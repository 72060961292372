<template>
	<!-- 保证金订单 -->
	<div class="bond_order">
		<div class="el-content mb12">
			<div class="mb12">
				<span class="f12">状态/方式：</span>
				<a-select v-model:value="search.pay_status" placeholder="支付状态" style="width: 200px;">
					<a-select-option value="">请选择支付状态</a-select-option>
					<a-select-option value="1">已支付</a-select-option>
					<a-select-option value="0">未支付</a-select-option>
				</a-select>
				<a-select v-model:value="search.pay_type" placeholder="支付方式" style="width: 200px;margin-left: 12px;">
					<a-select-option value="0">请选择支付方式</a-select-option>
					<a-select-option value="1">微信小程序支付</a-select-option>
					<a-select-option value="2">微信APP支付</a-select-option>
					<a-select-option value="3">支付宝支付</a-select-option>
					<a-select-option value="4">余额支付</a-select-option>
					<a-select-option value="5">支付宝APP支付</a-select-option>
				</a-select>
			</div>
			<div class="mb12">
				<span class="f12">订 单 编 号：</span>
				<a-input v-model:value="search.order_sn" placeholder="订单编号" style="width: 400px;"></a-input>
			</div>
			<div class="mb12">
				<span class="f12">交 易 号 ：</span>
				<a-input v-model:value="search.pay_sn" placeholder="订单编号" style="width: 400px;"></a-input>
			</div>
			<div class="mb12">
				<span class="f12">商 户 I D ：</span>
				<a-input v-model:value="search.shop_id" placeholder="订单编号" style="width: 400px;"></a-input>
			</div>
			<div class="mb12">
				<span class="f12">下单时间：</span>
				 <a-range-picker v-model:value="search.time" />
			</div>
			<a-button type="primary" @click="getShopBailOrderList(1,info.limit)" style="margin-left: 60px;">查询</a-button>
		</div>
		<div class="el-content">
			<a-table :pagination="false" row-key="order_id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'order_id'},
				{title:'订单编号',dataIndex:'order_sn'},
				{title:'订单总价',dataIndex:'amount'},
				{title:'支付方式',dataIndex:'pay_type',slots:{customRender:'pay_type'}},
				{title:'支付状态',dataIndex:'pay_status',slots:{customRender:'pay_status'}},
				{title:'支付交易号',dataIndex:'pay_sn'},
				{title:'创建时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]">
				<template #pay_type="{record}">
					<span>{{$util.payMethod( parseInt(record.pay_type))}}</span>
				</template>
				<template #pay_status="{record}">
					<a-tag :color="record.pay_status == 1 ? '#00CC66':'#999'">{{record.pay_status == 1 ?'已支付':'未支付'}}</a-tag>
				</template>
				<template #action="{record}">
					<com-pay-result :id="record.order_id" :type="6"></com-pay-result>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getShopBailOrderList(info.page,e)}"
					@change="(e)=>{getShopBailOrderList(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import comPayResult from "@/components/miniapp/com-pay-result.vue";
import { reactive, toRefs } from 'vue';
import shopModel from '@/api/addons/shop';
export default{
	components:{
		comPayResult
	},
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			search:{
				shop_id:'',
				order_sn:'',
				pay_sn:'',
				pay_type:'',
				pay_status:'',
				time:[],
			},
		})
		getShopBailOrderList(1,_d.info.limit)
		function getShopBailOrderList(page,limit){
			shopModel.getBondOrder(page,limit,_d.search,res=>_d.info = {limit,...res})
		}
		return{
			...toRefs(_d),
			getShopBailOrderList
		}
	}
}
</script>

<style>
</style>
